<template>
  <div class="classic-distance-milestone">
    <canvas :id="id" width="144" height="190"></canvas>
    <p>
      <button class="btn btn-link" @click.prevent="onSelect">
        <font-awesome-icon :icon="['fal', 'check-circle']" size="2x"></font-awesome-icon> {{ $t('Select') }}
      </button>
    </p>
  </div>
</template>

<script>
import classicDistances from '../classicDistances'
import formatterMethods from '../formatterMethods'
import EventBus from '../eventBus'
import { km2mi } from '@/constants'

export default {
  name: 'ClassicDistanceMilestone',
  mixins: [formatterMethods],
  props: {
    'label': String,
    'unit': String
  },
  data: function() {
    return {
      'selectedUnit': this.unit
    }
  },
  computed: {
    'id': function() {
      const randomInt = Math.floor(Math.random() * Math.floor(100))
      const uniqueId = this.label.toLowerCase().replace(' ', '') + '-' + randomInt
      return 'classic-distances-' + uniqueId
    },
    'distance': function() {
      let distance = classicDistances[this.label]
      if (this.selectedUnit === 'mi') {
        distance *= km2mi
      }
      return this.formatDistance(distance, this.selectedUnit)
    }
  },
  mounted: function() {
    this.draw()

    // Redraw milestone when unit or locale is changed
    let self = this
    EventBus.$on('unit-modified', function (payload) {
      self.selectedUnit = payload.unit
      self.redraw()
    })
    EventBus.$on('locale-modified', function () {
      self.redraw()
    })
  },
  methods: {
    draw() {
      let canvas = document.getElementById(this.id)
      let context = canvas.getContext('2d')
      context.beginPath()
      context.lineWidth = "2"
      context.strokeStyle = "black"
      context.arc(72, 72, 70, 0, Math.PI, true)
      context.stroke()
      context.fillStyle = "red"
      context.fill()
      context.rect(2, 70, 140, 110)
      context.stroke()
      context.fillStyle = "black"
      context.font = "16px -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,sans-serif"
      context.textAlign = "center"
      context.fillText(this.$t(this.label), 70, 60)
      context.font = "21px -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,sans-serif"
      context.fillText(this.distance, 70, 150)
    },
    redraw() {
      let canvas = document.getElementById(this.id)
      let context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      this.draw()
    },
    onSelect: function() {
      EventBus.$emit('classic-distance-selected', { distanceLabel: this.label })
    }
  }
}
</script>

<style scoped>
  .classic-distance-milestone {
    margin-top: 15px;
  }
  .classic-distance-milestone p {
    margin:-5px
  }
</style>
