<template>
  <footer class="text-center">
    <p>Copyright © {{ years }}, <router-link :to="{ path: $t('/en/about-this-website.html', $root.$i18n.locale) }">{{ $t('Some Rights Reserved') }}</router-link> | Version {{ version }} | <router-link :to="{ path: $t('/en/about-this-website.html') }">{{ $t('about.title') }}</router-link></p>
  </footer>
</template>

<script>
import { version } from '../constants'
export default {
  name: 'AppFooter',
  data: function() {
    return {
      'initialYear': 2020,
      'version': version
    }
  },
  computed: {
    years: function() {
      const currentYear = new Date().getFullYear()
      if (currentYear === this.initialYear) {
        return currentYear
      } else {
        return this.initialYear + '-' + currentYear
      }
    }
  }
}
</script>

<style scoped>
footer {
  color: #3b4351;
  margin: 20px 0 5px 0;
}

footer a:link {
  color: #3b4351;
  text-decoration: none;
}

footer a:visited {
  color: #3b4351;
}

footer a:hover {
  color: #000;
}

footer p {
  display: table;
  box-shadow: 5px 5px 15px 1px #000;
  background-color: #FFF;
  padding: 6px 10px;
  opacity: 0.95;
  margin: 0 auto 10px auto
}
</style>
