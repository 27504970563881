<template>
  <div class="modal modal-lg" :class="{ active: showModal }">
    <a href="#close" class="modal-overlay" :aria-label="$t('Close')" @click.prevent="close"></a>
    <div class="modal-container shadowed-content">
      <div class="modal-header">
        <a href="#close" class="btn btn-clear float-right" :aria-label="$t('Close')" @click.prevent="close"></a>
        <div class="modal-title h5">{{ $t('Classic Distances') }}</div>
      </div>
      <div class="modal-body">
        <div class="content">
          <div class="container">
            <div class="columns">
              <div class="column col-4 col-md-6 col-sm-12">
                <classic-distance-milestone label="5K" :unit="selectedUnit" />
              </div>
              <div class="column col-4 col-md-6 col-sm-12">
                <classic-distance-milestone label="10K" :unit="selectedUnit" />
              </div>
              <div class="column col-4 col-md-6 col-sm-12">
                <classic-distance-milestone label="Half Marathon" :unit="selectedUnit" />
              </div>
              <div class="column col-4 col-md-6 col-sm-12">
                <classic-distance-milestone label="Marathon" :unit="selectedUnit" />
              </div>
              <div class="column col-4 col-md-6 col-sm-12">
                <classic-distance-milestone label="100K" :unit="selectedUnit" />
              </div>
              <div class="column col-4 col-md-6 col-sm-12">
                <classic-distance-milestone label="100-Mile" :unit="selectedUnit" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="text-center">
          <div class="btn-group">
            <button class="btn btn-link" @click.prevent="close">
              <font-awesome-icon :icon="['fal', 'times-circle']" size="2x"></font-awesome-icon> {{ $t('Cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicDistanceMilestone from './ClassicDistanceMilestone.vue'
import EventBus from '../eventBus'

export default {
  name: 'ClassicDistancesModal',
  props: {
    'selectedUnit': String
  },
  components: {
    ClassicDistanceMilestone
  },
  data: function() {
    return {
      showModal: false
    }
  },
  mounted: function() {
    const self = this
    EventBus.$on('classic-distance-selected', function () {
      self.close()
    });
  },
  methods: {
    show() {
      this.showModal = true
    },
    close() {
      this.showModal = false
    }
  }
}
</script>
