// Each distance is defined by:
// label: distance in km
const classicDistances = {
  // https://runhive.com/running/world-records
  '5K': 5.000,
  '10K': 10.000,
  'Half Marathon': 21.097,
  'Marathon': 42.195,
  '100K': 100.000,
  '100-Mile': 160.9344
}
export default classicDistances;
