
import moment from 'moment'
import classicDistances from "./classicDistances.js"

// Each world record is defined by:
// title
// name
// time (hh:mm:ss.cs)
// image
// image url
// photographic credit

const worldRecords = {
  '5K': {
    'm': {
      'title': "Men's 5K WR",
      'name': 'Joshua Cheptegei',
      'time': '00:12:35.36',
      'image': 'Cheptegei_Kejelcha_Eugene_2014.jpg',
      'imageUrl': 'https://commons.wikimedia.org/wiki/File:Joshua_Kiprui_Cheptegei_Eugene_2014.jpg',
      'photographicCredit': 'Erik van Leeuwen / Wikimedia Commons'
    },
    'w': {
      'title': "Women's 5K WR",
      'name': 'Letesenbet Gidey',
      'time': '00:14:06.62',
      'image': 'Letesenbet_Gidey_Hengelo_2016.jpg',
      'imageUrl': 'https://commons.wikimedia.org/wiki/File:Letesenbet_Gidey_2016_FBK-Games.jpg',
      'photographicCredit': 'Erik van Leeuwen / Wikimedia Commons'
    }
  },

  '10K': {
    'm': {
      'title': "Men's 10K WR",
      'name': 'Joshua Cheptegei',
      'time': '00:26:11.00',
      'image': 'Cheptegei_Kejelcha_Eugene_2014.jpg',
      'imageUrl': 'https://commons.wikimedia.org/wiki/File:Joshua_Kiprui_Cheptegei_Eugene_2014.jpg',
      'photographicCredit': 'Erik van Leeuwen / Wikimedia Commons'
    },
    'w': {
      'title': "Women's 10K WR",
      'name': 'Letesenbet Gidey',
      'time': '00:29:01.03',
      'image': 'Letesenbet_Gidey_Hengelo_2016.jpg',
      'imageUrl': 'https://commons.wikimedia.org/wiki/File:Letesenbet_Gidey_2016_FBK-Games.jpg',
      'photographicCredit': 'Erik van Leeuwen / flickr'
    }
  },

  'Half Marathon': {
    'm': {
      'title': "Men's half marathon WR",
      'name': 'Jacob Kiplimo',
      'time': '00:57:31.00',
      'image': '',
      'imageUrl': '',
      'photographicCredit': ''
    },
    'w': {
      'title': "Women's half marathon WR",
      'name': 'Letesenbet Gidey',
      'time': '01:02:52.00',
      'image': 'Letesenbet_Gidey_Hengelo_2016.jpg',
      'imageUrl': 'https://commons.wikimedia.org/wiki/File:Letesenbet_Gidey_2016_FBK-Games.jpg',
      'photographicCredit': 'Erik van Leeuwen / Wikimedia Commons'
    }
  },

  'Marathon': {
    'm': {
      'title': "Men's marathon WR",
      'name': 'Eliud Kipchoge',
      'time': '02:01:39.00',
      'image': 'Eliud_Kipchoge_Berlin_2015.jpg',
      'imageUrl': 'https://commons.wikimedia.org/wiki/File:Berlin-Marathon_2015_Runners_1.jpg',
      'photographicCredit': 'Denis Barthel / Wikimedia Commons'
    },
    'w': {
      'title': "Women's marathon WR",
      'name': 'Brigid Jepscheschir Kosgei',
      'time': '02:14:04.00',
      'image': 'Brigid_Jepscheschir_Kosgei_London_2018.jpg',
      'imageUrl': 'https://commons.wikimedia.org/wiki/File:London_Marathon_2018_(27765192508).jpg',
      'photographicCredit': 'Paul Hudson / Wikimedia Commons'
    }
  },

  '100K': {
    'm': {
      'title': "Men's 100K WR",
      'name': 'Nao Kazami',
      'time': '06:09:14.00',
      'image': '',
      'imageUrl': '',
      'photographicCredit': ''
    },
    'w': {
      'title': "Women's 100K WR",
      'name': 'Tomoe Abe',
      'time': '06:33:11.00',
      'image': '',
      'imageUrl': '',
      'photographicCredit': ''
    }
  },

  '100-Mile': {
    'm': {
      'title': "Men's 100-Mile WR",
      'name': 'Aleksandr "Sania" Sorokin',
      'time': '10:51:39.00',
      'image': '',
      'imageUrl': '',
      'photographicCredit': ''
    },
    'w': {
      'title': "Women's 100-Mile WR",
      'name': 'Camille Herron',
      'time': '12:41:11.00',
      'image': '',
      'imageUrl': '',
      'photographicCredit': ''
    }
  }
}

// Calculate time, speed and pace for each world records
function calculateTimeSpeedAndPace(distanceLabel, worldRecord) {
  const distance = classicDistances[distanceLabel]
  const duration = moment(worldRecord.time, "hh:mm:ss.SS")
  worldRecord.time = 3600 * duration.hours() + 60 * duration.minutes() + duration.seconds()
      + duration.milliseconds() / 1000
  worldRecord.speed = distance * 3600 / worldRecord.time
  worldRecord.pace = 3600 / worldRecord.speed
}

for (var distanceLabel in worldRecords) {
  calculateTimeSpeedAndPace(distanceLabel, worldRecords[distanceLabel].w)
  calculateTimeSpeedAndPace(distanceLabel, worldRecords[distanceLabel].m)
}

export default worldRecords;
