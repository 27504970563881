<template>
  <div class="modal modal-lg" :class="{ active: showModal }">
    <a href="#close" class="modal-overlay" :aria-label="$t('Close')" @click.prevent="close"></a>
    <div class="modal-container shadowed-content">
      <div class="modal-header">
        <a href="#close" class="btn btn-clear float-right" :aria-label="$t('Close')" @click.prevent="close"></a>
        <div class="modal-title h5">{{ $t('Advanced Options') }}</div>
      </div>
      <div class="modal-body">
        <div class="content">
          <div class="container">
            <form class="form-horizontal">
              <!-- minDistance -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="minDistance" class="form-label">{{ minDistanceLabel }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <input
                    id="minDistance"
                    type="number"
                    step="0.001"
                    min="0.1"
                    max="999"
                    v-model.lazy.number="minDistance"
                    @keypress="isNumber($event)"
                    @change="onMinDistanceChange"
                    class="form-input"
                  >
                </div>
              </div>

              <!-- maxDistance -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="maxDistance" class="form-label">{{ maxDistanceLabel }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <input
                    id="maxDistance"
                    type="number"
                    step="0.001"
                    min="1"
                    max="999"
                    v-model.lazy.number="maxDistance"
                    @keypress="isNumber($event)"
                    @change="onMaxDistanceChange"
                    class="form-input"
                  >
                </div>
              </div>

              <!-- minTime -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="minTime" class="form-label">{{ $t('Minimum time (min)') }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <input
                    id="minTime"
                    type="number"
                    step="1"
                    min="1"
                    max="2880"
                    v-model.lazy.number="minTime"
                    @keypress="isDigit($event, 4)"
                    @change="onMinTimeChange"
                    class="form-input"
                  >
                </div>
              </div>

              <!-- maxTime -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="maxTime" class="form-label">{{ $t('Maximum time (min)') }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <input
                    id="maxTime"
                    type="number"
                    step="1"
                    min="1"
                    max="2880"
                    v-model.lazy.number="maxTime"
                    @keypress="isDigit($event, 4)"
                    @change="onMaxTimeChange"
                    class="form-input"
                  >
                </div>
              </div>

              <!-- minSpeed -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="minSpeed" class="form-label">{{ minSpeedLabel }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <input
                    id="minSpeed"
                    type="number"
                    step="0.001"
                    min="0.001"
                    max="30"
                    v-model.lazy.number="minSpeed"
                    @keypress="isNumber($event)"
                    @change="onMinSpeedChange"
                    class="form-input"
                  >
                </div>
              </div>

              <!-- maxSpeed -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="maxSpeed" class="form-label">{{ maxSpeedLabel }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <input
                    id="maxSpeed"
                    type="number"
                    step="0.001"
                    min="0.001"
                    max="30"
                    v-model.lazy.number="maxSpeed"
                    @keypress="isNumber($event)"
                    @change="onMaxSpeedChange"
                    class="form-input"
                  >
                </div>
              </div>

              <!-- Display classic distances -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="display-classic-distances" class="form-label">{{ $t('Display classic distances') }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <select
                          id="display-classic-distances"
                          v-model="displayClassicDistances"
                          class="form-select">
                    <option :value="1">{{ $t('Yes') }}</option>
                    <option :value="0">{{ $t('No') }}</option>
                  </select>
                </div>
              </div>

              <!-- numberOfLines -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="numberOfLines" class="form-label">{{ $t('Number of lines before and after pace target') }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <input
                    id="numberOfLines"
                    type="number"
                    step="1"
                    min="1"
                    max="99"
                    v-model.lazy.number="numberOfLines"
                    @keypress="isDigit($event, 2)"
                    class="form-input"
                  >
                </div>
              </div>

              <!-- numberOfSeconds -->
              <div class="form-group">
                <div class="col-8 col-md-12 text-center">
                  <label for="offset" class="form-label">{{ $t('Number of seconds offset for each line') }}</label>
                </div>
                <div class="col-2 col-md-12">
                  <input
                    id="offset"
                    type="number"
                    step="1"
                    min="1"
                    max="99"
                    v-model.lazy.number="numberOfSeconds"
                    @keypress="isDigit($event, 2)"
                    class="form-input"
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="text-center">
          <div class="btn-group">
            <button class="btn btn-link" @click.prevent="save">
              <font-awesome-icon :icon="['fal', 'save']" size="2x"></font-awesome-icon> {{ $t('Save') }}
            </button>
            <button class="btn btn-link" @click.prevent="close">
              <font-awesome-icon :icon="['fal', 'times-circle']" size="2x"></font-awesome-icon> {{ $t('Cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../eventBus'
import checkMethods from '../checkMethods'
import formatterMethods from "../formatterMethods";

export default {
  name: 'AdvancedOptionsModal',
  mixins: [checkMethods, formatterMethods],
  props: {
    unit: String,
    advancedOptions: Object
  },
  data: function() {
    return {
      minDistance: this.advancedOptions.minDistance,
      maxDistance: this.advancedOptions.maxDistance,
      minTime: this.advancedOptions.minTime / 60,
      maxTime: this.advancedOptions.maxTime / 60,
      minSpeed: this.advancedOptions.minSpeed,
      maxSpeed: this.advancedOptions.maxSpeed,
      displayClassicDistances: this.advancedOptions.displayClassicDistances,
      numberOfLines: this.advancedOptions.numberOfLines,
      numberOfSeconds: this.advancedOptions.numberOfSeconds,
      showModal: false
    }
  },
  mounted: function() {
    let self = this

    EventBus.$on('advanced-options-modified', function (payload) {
      self.minDistance = payload.minDistance
      self.maxDistance = payload.maxDistance
      self.minTime = payload.minTime / 60
      self.maxTime = payload.maxTime / 60
      self.minSpeed = payload.minSpeed
      self.maxSpeed = payload.maxSpeed
      self.displayClassicDistances = payload.displayClassicDistances
      self.numberOfLines = payload.numberOfLines
      self.numberOfSeconds = payload.numberOfSeconds
    });
  },
  computed: {
    minDistanceLabel: function () {
      const label = this.$t('Minimum distance')
      return label + ' (' + this.unit + ')'
    },
    maxDistanceLabel: function () {
      const label = this.$t('Maximum distance')
      return label + ' (' + this.unit + ')'
    },
    minSpeedLabel: function () {
      const label = this.$t('Minimum speed')
      return label + ' (' + this.unit + '/h)'
    },
    maxSpeedLabel: function () {
      const label = this.$t('Maximum speed')
      return label + ' (' + this.unit + '/h)'
    }
  },
  methods: {
    show() {
      this.showModal = true
    },
    close() {
      this.showModal = false
    },
    save() {
      EventBus.$emit('advanced-options-modified', {
        minDistance: this.minDistance,
        maxDistance: this.maxDistance,
        minTime: this.minTime * 60,
        maxTime: this.maxTime * 60,
        minSpeed: this.minSpeed,
        maxSpeed: this.maxSpeed,
        displayClassicDistances: this.displayClassicDistances,
        numberOfLines: this.numberOfLines,
        numberOfSeconds: this.numberOfSeconds,
      });
      this.close();
    },
    onMinDistanceChange() {
      if (this.minDistance > this.maxDistance) {
        this.minDistance = this.maxDistance
      }
      this.minDistance = this.round(this.minDistance)
    },
    onMaxDistanceChange() {
      if (this.maxDistance < this.minDistance) {
        this.maxDistance = this.minDistance
      }
      this.maxDistance = this.round(this.maxDistance)
    },
    onMinTimeChange() {
      if (this.minTime > this.maxTime) {
        this.minTime = this.maxTime
      }
    },
    onMaxTimeChange() {
      if (this.maxTime < this.minTime) {
        this.maxTime = this.minTime
      }
    },
    onMinSpeedChange() {
      if (this.minSpeed > this.maxSpeed) {
        this.minSpeed = this.maxSpeed
      }
      this.minSpeed = this.round(this.minSpeed)
    },
    onMaxSpeedChange() {
      if (this.maxSpeed < this.minSpeed) {
        this.maxSpeed = this.minSpeed
      }
      this.maxSpeed = this.round(this.maxSpeed)
    }
  }
}
</script>

<style scoped>
  .btn-group {
    margin-top: 20px
  }
</style>