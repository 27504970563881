import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import About from "../views/About";
import i18n from "../i18n";

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    redirect: '/en/index.html'
  },
  {
    path: '/:locale',
    redirect: '/:locale/index.html'
  },
  {
    path: '/:locale/index.html',
    component: Home
  },
  {
    path: '/en/about-this-website.html',
    component: About
  },
  {
    path: '/fr/a-propos-de-ce-site.html',
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Set locale from url if any
const url = window.location.pathname;
const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
locales.keys().forEach(key => {
  const matched = key.match(/([A-Za-z0-9-_]+)\./i)
  if (matched && matched.length > 1) {
    const locale = matched[1]
    if (url == '/' + locale || url.startsWith('/' + locale + '/')) {
      i18n.locale = locale
    }
  }
})

export default router
