<template>
  <div class="card">
    <div class="card-image text-center">
      <template v-if="worldRecord.imageUrl">
        <!-- <a :href="worldRecord.imageUrl"><img :ci-src="getImgUrl(worldRecord.image)" class="img-responsive" :alt="altText"></a> -->
        <a :href="worldRecord.imageUrl"><img :src="getImgUrl(worldRecord.image)" class="thumbnail" :alt="altText"></a>
      </template>
      <template v-else>
        <font-awesome-icon :icon="['fas', 'running']" size="8x" style="margin: 30px"></font-awesome-icon>
      </template>
    </div>
    <div class="card-header text-center">
      <div class="card-title h5">{{ worldRecord.name }}</div>
      <div class="card-subtitle">{{ $t(worldRecord.title) }}</div>
    </div>
    <div class="card-body text-center">
      <ul>
        <li>{{ $t('Time: ') }}{{ formattedTime }}</li>
        <li>{{ $t('Pace: ') }}{{ formattedPace }}</li>
        <li>{{ $t('Speed: ') }}{{ formattedSpeed }}</li>
      </ul>
      <button class="btn btn-link" @click.prevent="onSelect">
        <font-awesome-icon :icon="['fal', 'check-circle']" size="2x"></font-awesome-icon> {{ $t('Select') }}
      </button>
    </div>
  </div>
</template>

<script>
import { km2mi } from '@/constants'
import classicDistances from '../classicDistances'
import worldRecords from '../worldRecords'
import formatterMethods from '../formatterMethods'
import EventBus from '../eventBus'

export default {
  name: 'WorldRecordCard',
  mixins: [formatterMethods],
  props: {
    'distanceLabel': String,
    'sex': String,
    'unit': String
  },
  computed: {
    distance: function() {
      let distance = classicDistances[this.distanceLabel]
      if (this.unit === 'mi') {
        distance *= km2mi
      }
      return distance
    },
    worldRecord: function() {
      return worldRecords[this.distanceLabel][this.sex]
    },
    altText: function() {
      return this.worldRecord.name + ' (' + this.$t('photographic credit: ') + this.worldRecord.photographicCredit + ')'
    },
    formattedTime: function() {
      let printCentiseconds = false;
      if (this.unit == 'km' && this.distance <= 10) {
        printCentiseconds = true
      }
      if (this.unit == 'mi' && this.distance <= 10 * km2mi) {
        printCentiseconds = true
      }
      return this.formatTime(this.worldRecord.time, printCentiseconds)
    },
    formattedPace: function() {
      return this.formatPace(this.worldRecord.pace, this.unit, true);
    },
    formattedSpeed: function() {
      return this.formatSpeed(this.worldRecord.speed, this.unit);
    }
  },
  methods: {
    getImgUrl(image) {
      return require('../assets/wr/' + image)
    },
    onSelect: function() {
      EventBus.$emit('world-record-selected', { distanceLabel: this.distanceLabel, worldRecord: this.worldRecord })
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 20px 0;
}

.thumbnail {
  height: 250px;
  margin-top: 20px;
}
</style>
