import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import './assets/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHandPointer } from '@fortawesome/pro-light-svg-icons'
import { faStopwatch } from '@fortawesome/pro-light-svg-icons'
import { faCog } from '@fortawesome/pro-light-svg-icons'
import { faExpandWide } from '@fortawesome/pro-light-svg-icons'
import { faCompressWide } from '@fortawesome/pro-light-svg-icons'
import { faArrowsAlt } from '@fortawesome/pro-light-svg-icons'
import { faCompressArrowsAlt } from '@fortawesome/pro-light-svg-icons'
import { faBookmark } from '@fortawesome/pro-light-svg-icons'
import { faFileCsv } from '@fortawesome/pro-light-svg-icons'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons'
import { faLockAlt } from '@fortawesome/pro-light-svg-icons'
import { faLockOpenAlt } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { faWindowClose } from '@fortawesome/pro-light-svg-icons'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { faRunning } from '@fortawesome/free-solid-svg-icons'

// Init font awesome
library.add(faHandPointer)
library.add(faStopwatch)
library.add(faCog)
library.add(faExpandWide)
library.add(faCompressWide)
library.add(faArrowsAlt)
library.add(faCompressArrowsAlt)
library.add(faBookmark)
library.add(faFileCsv)
library.add(faFilePdf)
library.add(faLockAlt)
library.add(faLockOpenAlt)
library.add(faCheckCircle)
library.add(faSave)
library.add(faWindowClose)
library.add(faTimesCircle)
library.add(faRunning)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

// Enable Vue devtools
Vue.config.devtools = isProd

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  created() {
    const html = document.documentElement
    html.setAttribute('lang', i18n.locale)
  }
}).$mount('#app')

