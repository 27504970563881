  <template>
    <form id="selection-form" class="form-horizontal">
      <!-- Unit -->
      <div class="form-group">
        <div class="col-3 col-xl-4 col-md-12 text-center">
          <label for="unit" class="form-label">{{ $t('Unit of measurement') }}</label>
        </div>
        <div class="col-2 col-md-12">
          <select id="unit" v-model="unit" class="form-select">
            <option value="mi">{{ $t('Miles') }}</option>
            <option value="km">{{ $t('Kilometers') }}</option>
          </select>
        </div>
      </div>

      <!-- Target -->
      <div class="form-group">
        <div class="col-3 col-xl-4 col-md-12 text-center">
          <label for="target" class="form-label">{{ $t('Target') }}</label>
        </div>
        <div class="col-2 col-md-12">
          <select id="target" v-model="target" class="form-select">
            <option value="distance">{{ $t('Distance') }}</option>
            <option value="time">{{ $t('Time') }}</option>
          </select>
        </div>
      </div>

      <!-- Increment -->
      <div class="form-group">
        <div class="col-3 col-xl-4 col-md-12 text-center">
          <label for="increment" class="form-label">{{ incrementLabel }}</label>
        </div>
        <div class="col-2 col-md-12">
          <input
            id="increment"
            type="number"
            step="0.001"
            min="0.1"
            :max="distance"
            v-model.lazy.number="increment"
            class="form-input"
            @keypress="isNumber($event)"
            @change="onIncrementChange"
          >
        </div>
      </div>

      <!-- Distance -->
      <div class="form-group">
        <div class="col-3 col-xl-4 col-md-12 text-center">
          <label class="form-label" for="distance">{{ distanceLabel }}</label>
        </div>
        <div class="col-2 col-md-11">
          <div class="has-icon-left">
            <input
              id="distance"
              type="number"
              class="form-input"
              step="0.001"
              :min="minDistanceInForm"
              :max="maxDistanceInForm"
              v-model.lazy.number="roundedDistance"
              @keypress="isNumber($event)"
              @change="onDistanceChange"
            >
            <font-awesome-icon class="form-icon" :icon="['fal', this.target === 'distance' ? 'lock-alt' :  'lock-open-alt']"></font-awesome-icon>
          </div>
        </div>
      </div>

      <!-- Distance (slider) -->
      <!-- Set silent to true to hide errors because all the paramters, the min and max values are linked together -->
      <!-- Sample errors: The "value" must be greater than or equal to the "min". -->
      <div class="form-group hide-xl">
        <div class="col-12">
          <div class="form-input slider">
            <vue-slider
              v-model="roundedDistance"
              :marks="distanceMarks"
              :min="minDistanceInForm"
              :max="maxDistanceInForm"
              :lazy="true"
              :interval="0.001"
              :tooltip-formatter="distanceTooltipFormatter"
              :tooltip="'focus'"
              @change="onDistanceChange"
              :silent="true"
            ></vue-slider>
          </div>
        </div>
      </div>

      <!-- Time -->
      <div class="form-group">
        <div class="col-3 col-xl-4 col-md-12 text-center">
          <span class="form-label">{{ $t('Time') }} (h:m:s:cs)</span>
        </div>
        <div class="col-2 col-xl-2 col-md-3">
          <label class="invisible-label" for="time-hours">{{ $t('Hours') }}</label>
          <div class="has-icon-left">
            <input
              id="time-hours"
              type="number"
              :min="0"
              :max="24"
              :step="1"
              v-model.number.lazy="timeHours"
              @keypress="isDigit($event, 2)"
              class="form-input time-input"
              @change="onTimeHoursMinutesSecondsChange"
            >
            <font-awesome-icon class="form-icon" :icon="['fal', this.target === 'distance' ? 'lock-open-alt' : 'lock-alt']"></font-awesome-icon>
            <span class="time-separator">:</span>
          </div>
        </div>
        <div class="col-2 col-xl-2 col-md-3">
          <label class="invisible-label" for="time-minutes">{{ $t('Minutes') }}</label>
          <div class="has-icon-left">
            <input
              id="time-minutes"
              type="number"
              :min="0"
              :max="59"
              :step="1"
              v-model.number.lazy="timeMinutes"
              @keypress="isDigit($event, 2)"
              class="form-input time-input"
              @change="onTimeHoursMinutesSecondsChange"
            >
            <font-awesome-icon class="form-icon" :icon="['fal', this.target === 'distance' ? 'lock-open-alt' : 'lock-alt']"></font-awesome-icon>
            <span class="time-separator">:</span>
          </div>
        </div>
        <div class="col-2 col-xl-2 col-md-3">
          <label class="invisible-label" for="time-seconds">{{ $t('Seconds') }}</label>
          <div class="has-icon-left">
            <input
              id="time-seconds"
              type="number"
              :min="0"
              :max="59"
              :step="1"
              v-model.number.lazy="timeSeconds"
              @keypress="isDigit($event, 2)"
              class="form-input time-input"
              @change="onTimeHoursMinutesSecondsChange"
            >
            <font-awesome-icon class="form-icon" :icon="['fal', this.target === 'distance' ? 'lock-open-alt' : 'lock-alt']"></font-awesome-icon>
            <span class="time-separator">:</span>
          </div>
        </div>
        <div class="col-2 col-xl-2 col-md-3">
          <label class="invisible-label" for="time-centiseconds">{{ $t('Centiseconds') }}</label>
          <div class="has-icon-left">
            <input
              id="time-centiseconds"
              type="number"
              :min="0"
              :max="99"
              :step="1"
              v-model.number.lazy="timeCentiseconds"
              @keypress="isDigit($event, 2)"
              class="form-input time-input"
              @change="onTimeHoursMinutesSecondsChange"
            >
            <font-awesome-icon class="form-icon" :icon="['fal', this.target === 'distance' ? 'lock-open-alt' : 'lock-alt']"></font-awesome-icon>
          </div>
        </div>
      </div>

      <!-- Time (slider) -->
      <!-- Set silent to true to hide errors because all the paramters, the min and max values are linked together -->
      <div class="form-group hide-xl">
        <div class="col-12">
          <div class="form-input slider">
            <vue-slider
              v-model="roundedTime"
              :marks="timeMarks"
              :min="minTimeInForm"
              :max="maxTimeInForm"
              :interval="0.01"
              :lazy="true"
              :tooltip-formatter="timeTooltipFormatter"
              :tooltip="'focus'"
              :silent="true"
              @change="onTimeChange"
            ></vue-slider>
          </div>
        </div>
      </div>

      <!-- Speed -->
      <div class="form-group">
        <div class="col-3 col-xl-4 col-md-12 text-center">
          <label class="form-label" for="speed">{{ speedLabel }}</label>
        </div>
        <div class="col-2 col-lg-2 col-md-12">
          <input
            id="speed"
            type="number"
            class="form-input"
            step="0.001"
            :min="minSpeed"
            :max="maxSpeed"
            v-model.lazy.number="roundedSpeed"
            @keypress="isNumber($event)"
            @change="onSpeedChange"
          >
        </div>
      </div>

      <!-- Speed (slider) -->
      <div class="form-group hide-xl">
        <div class="col-12">
          <div class="form-input slider">
            <vue-slider
              v-model="roundedSpeed"
              :min="minSpeed"
              :max="maxSpeed"
              :interval="0.001"
              :lazy="true"
              :tooltip-formatter="speedTooltipFormatter"
              :tooltip="'focus'"
              :marks="speedMarks"
              @change="onSpeedChange"
            ></vue-slider>
          </div>
        </div>
      </div>

      <!-- Pace -->
      <div class="form-group">
        <div class="col-3 col-xl-4 col-md-12 text-center">
          <span class="form-label">{{ paceLabel }}</span>
        </div>
        <div class="col-2 col-lg-2 col-md-3">
          <label class="invisible-label" for="pace-minutes">{{ $t('Minutes') }}</label>
          <input
            id="pace-minutes"
            type="number"
            :min="0"
            :max="59"
            :step="1"
            v-model.number.lazy="paceMinutes"
            @keypress="isDigit($event, 2)"
            class="form-input time-input"
            @change="onPaceMinutesSecondsChange"
          >
          <span class="time-separator">:</span>
        </div>
        <div class="col-2 col-lg-2 col-md-3">
          <label class="invisible-label" for="pace-seconds">{{ $t('Seconds') }}</label>
          <input
            id="pace-seconds"
            type="number"
            :min="0"
            :max="59"
            :step="1"
            v-model.number.lazy="paceSeconds"
            @keypress="isDigit($event, 2)"
            class="form-input time-input"
            @change="onPaceMinutesSecondsChange"
          >
          <span class="time-separator">:</span>
        </div>
        <div class="col-2 col-lg-2 col-md-3">
          <label class="invisible-label" for="pace-centiseconds">{{ $t('Centiseconds') }}</label>
          <input
            id="pace-centiseconds"
            type="number"
            :min="0"
            :max="99"
            :step="1"
            v-model.number.lazy="paceCentiseconds"
            @keypress="isDigit($event, 2)"
            class="form-input time-input"
            @change="onPaceMinutesSecondsChange"
          >
        </div>        
      </div>

      <!-- Pace (slider) -->
      <div class="form-group hide-xl">
        <div class="col-12">
          <div class="form-input slider">
            <vue-slider
              v-model="roundedPace"
              :marks="paceMarks"
              :min="minPace"
              :max="maxPace"
              :lazy="true"
              :interval="0.01"
              :tooltip-formatter="paceTooltipFormatter"
              :tooltip="'focus'"
              @change="onPaceChange"
            ></vue-slider>
          </div>
        </div>
      </div>
    </form>
  </template>

  <script>
  import moment from 'moment'
  import 'moment-duration-format'
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/antd.css'
  import EventBus from '../eventBus'
  import { km2mi } from '@/constants'
  import defaultValues from '../defaultValues'
  import classicDistances from '../classicDistances'
  import worldRecords from '../worldRecords'
  import formatterMethods from '../formatterMethods'
  import checkMethods from '../checkMethods'

  export default {
    name: 'SelectionForm',

    mixins: [defaultValues, checkMethods, formatterMethods],

    components: {
      VueSlider
    },

    props: {
      targets: Object,
      options: Object,
      advancedOptions: Object
    },

    data: function () {
      return {
        // Targets (rounded values)
        distance: this.targets.distance,
        roundedDistance: this.round(this.targets.distance),
        time: this.targets.time,
        roundedTime: this.round(this.targets.time, 2),
        timeHours: null,
        timeMinutes: null,
        timeSeconds: null,
        timeCentiseconds: null,

        speed: this.targets.speed,
        roundedSpeed: this.round(this.targets.speed),
        pace: this.targets.pace,
        roundedPace: this.round(this.targets.pace, 2),
        paceMinutes: null,
        paceSeconds: null,
        paceCentiseconds: null,

        // Options
        unit: this.options.unit,
        target: this.options.target,
        increment: this.options.increment,

        // Advanced options
        minDistance: this.advancedOptions.minDistance,
        maxDistance: this.advancedOptions.maxDistance,
        minTime: this.advancedOptions.minTime,
        maxTime: this.advancedOptions.maxTime,
        minSpeed: this.advancedOptions.minSpeed,
        maxSpeed: this.advancedOptions.maxSpeed,
        displayClassicDistances: this.advancedOptions.displayClassicDistances
      }
    },

    created: function() {
      this.computeTime()
      this.computePace()
    },

    mounted: function() {
      let self = this
      EventBus.$on('classic-distance-selected', function (payload) {
        let distance = classicDistances[payload.distanceLabel]
        if (self.unit === 'mi') {
          distance *= km2mi
        }
        self.roundedDistance = distance
        self.onDistanceChange()
      })

      EventBus.$on('world-record-selected', function (payload) {
        self.distance = classicDistances[payload.distanceLabel]
        self.time = payload.worldRecord.time
        self.pace = payload.worldRecord.pace
        self.speed = payload.worldRecord.speed

        if (self.unit === 'mi') {
          self.distance *= km2mi;
          self.pace /= km2mi          
          self.speed = self.speed * km2mi
        }

        self.selectionFormIsModified()
      })

      EventBus.$on('advanced-options-modified', function (payload) {
        self.minSpeed = payload.minSpeed
        self.maxSpeed = payload.maxSpeed
        self.minDistance = payload.minDistance
        self.maxDistance = payload.maxDistance
        self.minTime = payload.minTime
        self.maxTime = payload.maxTime

        if (self.speed > payload.maxSpeed) {
          self.speed = payload.maxSpeed
          self.onSpeedChange()
        }
        if (self.speed < payload.minSpeed) {
          self.speed = payload.minSpeed
          self.onSpeedChange()
        }

        if (self.time > this.maxTimeInForm) {
          self.time = this.maxTimeInForm
          self.onTimeChange()
        }
        if (self.time < this.minTimeInForm) {
          self.time = this.minTimeInForm
          self.onTimeChange()
        }

        if (self.distance > self.maxDistanceInForm) {
          self.distance = self.maxDistanceInForm
          self.onDistanceChange()
        }
        if (self.distance < self.minDistanceInForm) {
          self.distance = self.minDistanceInForm
          self.onDistanceChange()
        }
      })
    },

    computed: {
      /* Pace */
      minPace: function () {
        return this.round(3600 / this.maxSpeed, 2)
      },

      maxPace: function () {
        return this.round(3600 / this.minSpeed, 2)
      },

      /* Min and max distance */
      minDistanceInForm: function() {
        if (this.target === 'distance') {
          return this.minDistance
        } else {
          return this.round(this.time * this.minSpeed / 3600)
        }
      },

      maxDistanceInForm: function () {
        if (this.target === 'distance') {
          return this.maxDistance
        } else {
          return this.round(this.time * this.maxSpeed / 3600)
        }
      },

      /* Min and max time */
      minTimeInForm: function() {
        if (this.target === 'distance') {
          return this.round(this.distance * 3600 / this.maxSpeed, 2)
        } else {
          return this.minTime
        }
      },

      maxTimeInForm: function () {
        if (this.target === 'distance') {
          return this.round(this.distance * 3600 / this.minSpeed, 2)
        } else {
          return this.maxTime
        }
      },

      /* Labels */
      speedLabel: function () {
        return this.$t('Speed') + ' (' + this.unit + '/h)';
      },

      paceLabel: function () {
        return this.$t('Pace per') + ' ' + this.unit + ' (m:s:cs)'
      },

      distanceLabel: function () {
        return this.$t('Distance') + ' (' + this.unit + ')'
      },

      incrementLabel: function () {
        return this.$t('Increment') + ' (' + this.unit + ')'
      },

      /* Marks */
      speedMarksIncrement: function () {
        const interval = this.maxSpeed - this.minSpeed;
        if (interval >= 15) {
          return 5
        } else if (interval >= 10) {
          return 3
        } else if (interval >= 5) {
          return 2
        } else if (interval > 2) {
          return 1
        } else {
          return 0.5
        }
      },

      speedMarks: function () {
        const marks = {}

        // Add marks for the work records
        let style = {
          width: '8px',
          height: '8px',
          display: 'block',
          transform: 'translate(-2px, -2px)',
          backgroundColor: '#e3706c'
        };

        const speeds = []
        let speed
        for (let distanceLabel in worldRecords) {
          speed = worldRecords[distanceLabel].w.speed
          if (this.unit === 'mi') {
            speed *= km2mi
          }
          if (speed >= this.minSpeed && speed <= this.maxSpeed) {
            speeds.push(speed)
          }

          speed = worldRecords[distanceLabel].m.speed
          if (this.unit === 'mi') {
            speed *= km2mi
          }
          if (speed >= this.minSpeed && speed <= this.maxSpeed) {
            speeds.push(speed)
          }
        }

        for (let i = 0; i < speeds.length; i++) {
          // Clone style object
          style = JSON.parse(JSON.stringify(style))
          marks[speeds[i]] = { label: "", style: style }
        }

        // Add other marks
        style = {
          width: '1px',
          height: '6px',
          display: 'block',
          backgroundColor: '#a0ceed',
          transform: 'translate(-2px, -2px)'
        };
        speed = this.minSpeed
        let roundedSpeed
        while (speed <= this.maxSpeed) {
          roundedSpeed = this.round(speed)
          marks[roundedSpeed] = { label: roundedSpeed, style: style }
          speed += this.speedMarksIncrement
        }

        return marks
      },

      paceMarksIncrement: function () {
        const interval = this.maxPace - this.minPace
        if (interval >= 300) {
          return 120;
        } else if (interval >= 120) {
          return 60;
        } else if (interval >= 60) {
          return 20;
        } else {
          return 10;
        }
      },

      paceMarks: function () {
        const marks = {};

        const style = {
          width: '1px',
          height: '6px',
          display: 'block',
          backgroundColor: '#a0ceed',
          transform: 'translate(-2px, -2px)'
        };
        let pace = this.minPace;
        while (pace <= this.maxPace) {
          marks[pace] = { label: this.formatPace(pace, this.targets.unit, false), style: style }
          pace += this.paceMarksIncrement
        }

        return marks;
      },

      distanceMarksIncrement: function () {
        const interval = this.maxDistanceInForm - this.minDistanceInForm
        if (interval <= 20) {
          return 5
        } else if (interval <= 50) {
          return 10
        } else if (interval <= 100) {
          return 25
        } else if (interval <= 200) {
          return 50
        } else {
          return 100
        }
      },

      distanceMarks: function () {
        let distances = {};

        let style = {
          width: '8px',
          height: '8px',
          display: 'block',
          transform: 'translate(-2px, -2px)',
          backgroundColor: '#e3706c'
        };

        let distance;
        if (this.target === 'distance') {
          for (const label in classicDistances) {
            style = JSON.parse(JSON.stringify(style))
            distance = classicDistances[label]
            if (this.unit === 'mi') {
              distance = Number.parseFloat(distance * km2mi).toFixed(3)
            }
            if (distance >= this.minDistanceInForm && distance <= this.maxDistanceInForm) {
              distances[distance] = {label: this.$t(label), style: style}
            }
          }
        } else {
          // Add marks for classic distances
          for (const label in classicDistances) {
            style = JSON.parse(JSON.stringify(style))
            distance = classicDistances[label]
            if (this.unit === 'mi') {
              distance = (distance * km2mi).toFixed(3)
            }
            if (distance >= this.minDistanceInForm && distance <= this.maxDistanceInForm) {
              distances[distance] = {label: '', style: style}
            }
          }

          // Add other marks
          style = {
            width: '8px',
            height: '8px',
            display: 'block',
            transform: 'translate(-2px, -2px)'
          };

          distance = this.distanceMarksIncrement
          while (distance <= this.maxDistanceInForm) {
            distances[distance] = { label: this.formatDistance(distance, this.unit), style: style }
            distance += this.distanceMarksIncrement
          }
        }

        return distances;
      },

      timeMarksIncrement: function () {
        const interval = this.maxTimeInForm - this.minTimeInForm;

        if (interval <= 3600) {
          return 900
        } else if (interval <= 3600 * 2) {
          return 1800
        } else if (interval <= 3600 * 5) {
          return 3600
        } else if (interval <= 3600 * 10) {
          return 7200
        } else {
          return 3600 * 5
        }
      },

      timeMarks: function () {
        const marks = {}

        const style = {
          width: '8px',
          height: '8px',
          display: 'block',
          transform: 'translate(-2px, -2px)'
        };

        let time = this.timeMarksIncrement
        while (time <= this.maxTimeInForm) {
          marks[time] = { label: this.formatTime(time, false), style: style }
          time += this.timeMarksIncrement
        }

        return marks;
      },
    },

    watch: {

      // NB: the intermediate times are being calculated from:
      // the pace (which is never rounded)
      // the targeted distance (rounded to one decimal)

      unit: function () {
        if (this.unit === 'km') {
          // Miles to kilometers
          this.minDistance = this.round(this.minDistance / km2mi)
          this.maxDistance = this.round(this.maxDistance / km2mi)
          this.minSpeed = this.round(this.minSpeed / km2mi)
          this.maxSpeed = this.round(this.maxSpeed / km2mi)

          this.distance = this.distance / km2mi
          this.speed = this.speed / km2mi

        } else if (this.unit === 'mi') {
          // Kilometers to miles
          this.minDistance = this.round(this.minDistance * km2mi)
          this.maxDistance = this.round(this.maxDistance * km2mi)
          this.minSpeed = this.round(this.minSpeed * km2mi)
          this.maxSpeed = this.round(this.maxSpeed * km2mi)

          this.distance = this.distance * km2mi
          this.speed = this.speed * km2mi

        } else {
          throw 'Incorrect unit: ' + this.unit
        }

        this.pace = 3600 / this.speed
        this.selectionFormIsModified()

        EventBus.$emit('advanced-options-modified', {
          minDistance: this.minDistance,
          maxDistance: this.maxDistance,
          minTime: this.minTime,
          maxTime: this.maxTime,
          minSpeed: this.minSpeed,
          maxSpeed: this.maxSpeed,
          displayClassicDistances: this.displayClassicDistances,
          numberOfLines: this.advancedOptions.numberOfLines,
          numberOfSeconds: this.advancedOptions.numberOfSeconds
        })

        EventBus.$emit('unit-modified', {
          unit: this.unit
        })
      },

      target: function() {
        if (this.target === 'distance') {
          let distanceIsChanged = false
          if (this.distance > this.maxDistance) {
            this.distance = this.maxDistance
            distanceIsChanged = true
          }
          if (this.distance < this.minDistance) {
            this.distance = this.minDistance
            distanceIsChanged = true
          }
          if (distanceIsChanged) {
            this.onDistanceChange()
          }

        } else if (this.target === 'time') {
          let timeIsChanged = false
          if (this.time > this.maxTime) {
            this.time = this.maxTime
            timeIsChanged = true
          }
          if (this.time < this.minTime) {
            this.time = this.minTime
            timeIsChanged = true
          }
          if (timeIsChanged) {
            this.onTimeChange()
            this.computeTime()
          }

        } else {
          throw 'Incorrect target: ' + this.target
        }
        this.selectionFormIsModified()
      },

      increment: function () {
        if (this.increment === '') {
          this.increment = this.defaultValues.increment
        }
        if (this.increment > this.distance) {
          this.increment = this.distance
        }
        if (this.increment < 0.01) {
          this.increment = 0.01
        }      
        this.selectionFormIsModified()
      },

      displayIncrement: function() {
        this.selectionFormIsModified()
      },

      displayClassicDistances: function() {
        this.selectionFormIsModified()
      }

      // Watching for distance, speed, pace and time changes does not work because these variables are linked together.
      // onChange callbacks are used (see below)
    },

    methods: {
      onIncrementChange: function() {
        // Sanity checks
        if (this.increment === '') {
          this.increment = this.defaultValues.increment
        }
        this.increment = this.round(this.increment)
      },

      onDistanceChange() {
        // Sanity checks
        if (this.roundedDistance === '') {
          this.roundedDistance = this.defaultValues.distance
          if (this.unit === 'mi') {
            this.roundedDistance *= km2mi
          }
        }
        this.roundedDistance = this.round(this.roundedDistance)
        if (this.roundedDistance > this.maxDistanceInForm) {
          this.roundedDistance = this.maxDistanceInForm
        }
        if (this.roundedDistance < this.minDistanceInForm) {
          this.roundedDistance = this.minDistanceInForm
        }

        this.distance = this.roundedDistance

        if (this.increment > this.distance) {
          this.increment = this.distance
        }

        if (this.target === 'distance') {
          // Target is distance
          // Compute time and pace from distance and speed
          this.time = 3600 * this.distance / this.speed
          this.pace = 3600 / this.speed

        } else {
          // Target is time
          // Compute speed and pace from distance and time
          this.speed = 3600 * this.distance / this.time
          this.pace = 3600 / this.speed
        }

        this.selectionFormIsModified()
      },

      onTimeChange() {
        // Sanity checks
        if (this.roundedTime === '') {
          this.roundedTime = this.defaultValues.time
        }
        if (this.roundedTime > this.maxTimeInForm) {
          this.roundedTime = this.maxTimeInForm
        }
        if (this.roundedTime < this.minTimeInForm) {
          this.roundedTime = this.minTimeInForm
        }

        this.time = this.roundedTime

        if (this.target === 'distance') {
          // Target is distance
          // Compute speed and pace from distance and time
          this.speed = 3600 * this.distance / this.time
          this.pace = 3600 / this.speed

        } else {
          // Target is time
          // Compute distance and pace from speed and time
          this.distance = this.speed * this.time / 3600
          this.pace = 3600 / this.speed
        }

        this.selectionFormIsModified();
      },

      onTimeHoursMinutesSecondsChange() {
        this.roundedTime = 3600 * this.timeHours + 60 * this.timeMinutes + this.timeSeconds
          + this.timeCentiseconds / 100

        this.onTimeChange()
      },

      /* Pace change handler */
      onPaceChange: function() {
        // Sanity checks
        if (this.roundedPace === '') {
          this.roundedPace = this.defaultValues.pace
        }
        if (this.roundedPace > this.maxPace) {
          this.roundedPace = this.maxPace
        }
        if (this.roundedPace < this.minPace) {
          this.roundedPace = this.minPace
        }

        this.pace = this.roundedPace

        if (this.target === 'distance') {
          // Target is distance
          // Compute speed and time from distance and pace
          this.speed = 3600 / this.pace
          this.time = this.pace * this.distance
        } else {
          // Target is time
          // Compute distance and speed from time and pace
          this.distance = this.time / this.pace
          this.speed = 3600 / this.pace
        }

        this.selectionFormIsModified()
      },

      onPaceMinutesSecondsChange: function() {
        this.roundedPace = 60 * this.paceMinutes + this.paceSeconds + this.paceCentiseconds / 100

        this.onPaceChange()
      },

      onSpeedChange: function () {
        // Sanity checks
        if (this.roundedSpeed === '') {
          this.roundedSpeed = this.defaultValues.speed
          if (this.unit === 'mi') {
            this.roundedSpeed = this.defaultValues.speed * km2mi
          }
        }
        this.roundedSpeed = this.round(this.roundedSpeed)
        if (this.roundedSpeed > this.maxSpeed) {
          this.roundedSpeed = this.maxSpeed
        }
        if (this.roundedSpeed < this.minSpeed) {
          this.roundedSpeed = this.minSpeed
        }
        this.speed = this.roundedSpeed

        if (this.target === 'distance') {
          // Target is distance
          // Compute time and pace from speed and distance
          this.pace = 3600 / this.speed
          this.time = 3600 * this.distance / this.speed
        } else {
          // Target is time
          // Compute distance and pace from time and speed
          this.distance = this.time * this.speed / 3600
          this.pace = 3600 / this.speed
        }

        this.selectionFormIsModified()
      },

      /* Change helper methods */
      computeTime() {
        const duration = moment.duration(this.time, 'seconds')
        if (duration.asHours() === 24) {
          this.timeHours = 24
          this.timeMinutes = 0
          this.timeSeconds = 0
          this.timeCentiseconds = 0
        } else {
          this.timeHours = duration.hours()
          this.timeMinutes = duration.minutes()
          this.timeSeconds = duration.seconds()
          this.timeCentiseconds = Math.round(duration.milliseconds() / 10)
          if (this.timeCentiseconds === 100) {
            this.timeCentiseconds = 0
            this.timeSeconds++
            if (this.timeSeconds === 60) {
              this.timeSeconds = 0
              this.timeMinutes++
              if (this.timeMinutes === 60) {
                this.timeMinutes = 0
                this.timeHours++
              }
            }
          }
        }
      },

      computePace() {
        const duration = moment.duration(this.pace, 'seconds');
        this.paceMinutes = duration.minutes()
        this.paceSeconds = duration.seconds()
        this.paceCentiseconds = Math.round(duration.milliseconds() / 10)
        if (this.paceCentiseconds === 100) {
          this.paceCentiseconds = 0
          this.paceSeconds++
          if (this.paceSeconds === 60) {
            this.paceSeconds = 0
            this.paceMinutes++
          }
        }
      },

      selectionFormIsModified: function() {
        // Emit event with modified values
        const targets = {
          distance: this.distance,
          time: this.time,
          speed: this.speed,
          pace: this.pace
        }
        const options = {
          unit: this.unit,
          target: this.target,
          increment: this.increment
        }
        EventBus.$emit('selection-form-modified', { 'targets': targets, 'options': options })

        // Round values so that they can be displayed in the form (except time and pace that can be
        // displayed without to be rounded)
        this.roundedDistance = this.round(this.distance)
        this.roundedTime = this.round(this.time, 2)
        this.computeTime()
        this.roundedSpeed = this.round(this.speed)
        this.roundedPace = this.round(this.pace, 2)
        this.computePace()
      },

      /* ----- Tooltip formatters ----- */
      timeTooltipFormatter: function (time) {
        return this.formatTime(time, true)
      },

      paceTooltipFormatter: function (pace) {
        return this.formatPace(pace, this.unit, true)
      },

      speedTooltipFormatter: function (speed) {
        return this.formatSpeed(speed, this.unit)
      },

      distanceTooltipFormatter: function (distance) {
        return this.formatDistance(distance, this.unit)
      }
    }
  }
  </script>

  <style scoped>
  .form-select option {
    background-color: #fff;
    opacity: 0.95;
  }
  .time-separator {
    margin-left: 5px;
  }
  .time-input {
    width: 80%;
    display: inline;
  }
  .slider {
    border: 0;
    margin-bottom: 30px
  }
  select option {
    background-color: transparent;
    opacity: 0.95;
  }
  .invisible-label {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  </style>