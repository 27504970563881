import moment from 'moment'
import 'moment-duration-format'

const formatterMethods = {
  methods: {
    checkUnit(unit) {
      if (unit !== 'mi' && unit !== 'km') {
        throw 'Unsupported unit: ' + unit
      }
    },
    // Print the pace value in human readable format (mm:ss or mm:ss:SS)
    // Supported units are: mi or km
    // Eg: 04:02.22 min/mi or 03:59 min/km
    formatPace: function (pace, unit, printCentiseconds) {
      if (unit) {
        this.checkUnit(unit)
      }
      const duration = moment.duration(pace, 'seconds')
      let format = 'mm:ss'
      if (printCentiseconds) {
        format += '.SS'
      }
      if (duration.hours() > 0) {
        format = 'hh:' + format
      }
      let formattedPace = duration.format(format)
      if (unit) {
        formattedPace += ' min/' + unit
      }
      return formattedPace;
    },

    // Print the speed value (in km/h) in the given unit (2 decimal places)
    // Supported units are: mi or km
    // Eg: 10.22 km/h or 7.96 mi/h
    formatSpeed: function (speed, unit) {
      this.checkUnit(unit)
      return parseFloat(speed).toFixed(3) + ' ' + unit + '/h'
    },

    // Print the given distance in the given unit (3 decimal place)
    // Supported units are: mi or km    
    // Eg: 42.195 km
    formatDistance: function (distance, unit) {
      this.checkUnit(unit)
      return parseFloat(distance).toFixed(3) + ' ' + unit
    },

    // Print the given time value in s in human readable format (hh:mm:ss)
    // Eg: 04:02 or 01:04:02
    formatTime: function (time, printCentiseconds) {
      const duration = moment.duration(time, 'seconds')
      let format = 'm [min] '

      if (printCentiseconds) {
        format += 's.SS [s]'
      } else {
        format += 's [s]'
      }
      if (duration.asHours() > 0) {
        format = 'h [h] ' + format
      }

      // why mins?
      return duration.format(format).replace('mins', 'min')
    },

    round: function(value, fractionDigits = 3) {
      let result = Number.parseFloat(value).toFixed(fractionDigits)
      return Number.parseFloat(result)
    }
  }
}
export default formatterMethods
