<template>
  <header class="navbar">
    <section class="navbar-section title-section">
      <h1><router-link :to="{ path: $t('/en/index.html', $root.$i18n.locale) }"><font-awesome-icon :icon="['fas', 'running']"></font-awesome-icon> {{ $t('home.title') }}</router-link></h1>
    </section>
    <section class="navbar-section hide-xl">
      <div class="btn-group">
        <button class="btn" v-show="!fullWidth" @click="toogleContentWidth">
          <font-awesome-icon :icon="['fal', 'expand-wide']" size="2x"></font-awesome-icon> {{ $t('Full width') }}
        </button>
        <button class="btn" v-show="fullWidth" @click="toogleContentWidth">
          <font-awesome-icon :icon="['fal', 'compress-wide']" size="2x"></font-awesome-icon> {{ $t('Small width') }}
        </button>
      </div>
    </section>
    <section class="navbar-section">
      <template v-for="lang in langs">
        <a :href="$t($router.currentRoute.path, lang)" @click.prevent="changeLocale(lang)" :key="lang"><span :class="`flag-icon flag-icon-${lang} flag`"></span></a>
      </template>
    </section>
  </header>
</template>

<script>
import 'flag-icon-css/css/flag-icons.min.css'
import EventBus from '../eventBus'

export default {
  name: 'AppHeader',
  data: function () {
    return {
      fullWidth: false,
      langs: ['us', 'fr'],
    }
  },
  computed: {
    homepage: function() {
      return '/' + this.$root.$i18n.locale
    }
  },
  methods: {
    changeLocale(lang) {
      const locale = (lang === 'us') ? 'en' : lang

      this.$root.$i18n.locale = locale
      const route = this.$router.currentRoute.path
      let translatedRoute = this.$t(route)
      if (window.location.hash) {
        translatedRoute += '#' + window.location.hash
      }
      history.pushState({}, null, translatedRoute)

      // Update lang attribute of html tag
      const html = document.documentElement
      html.setAttribute('lang', locale)

      EventBus.$emit('locale-modified', { locale })
    },
    toogleContentWidth() {
      this.fullWidth = !this.fullWidth
      EventBus.$emit('content-width-modified', { fullWidth: this.fullWidth })
    }
  }
}
</script>

<style scoped>
header {
  margin-top: 15px;
}

header a {
  color: #3b4351;
}

header a:link {
  color: #3b4351;
  text-decoration: none;
}

header a:visited {
  color: #3b4351;
}

header a:hover {
  color: #000;
}

.flag {
  width: 2em;
  line-height: 1.5em;
  margin: 10px
}

.navbar .navbar-section {
  flex: none;
}

@media screen and (max-width: 960px) {
  .navbar .navbar-section {
    flex: 1 1 0;
  }

  .navbar .title-section {
    flex: 3 1 0;
  }
}

.title-section h1 {
  margin-left: 10px;
}
</style>
