<template>
  <!-- prerendering does not work without id="app" -->
  <div id="app" class="container" :class="contentClass">
    <app-header></app-header>

    <router-view/>

    <app-footer></app-footer>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter.vue'
// This version does not work with vue
//import 'js-cloudimage-responsive'
// Does not work
//import 'js-cloudimage-responsive/plain'
import 'lazysizes'

export default {
  components: { AppHeader, AppFooter },

  data: function () {
    return {
      contentClass: 'grid-xl'
    }
  },
  
  mounted: function() {
    const self = this
    EventBus.$on('content-width-modified', function (payload) {
      self.contentClass = payload.fullWidth ? '' : 'grid-xl'
    })

    // Enable cloudimage magic
    window.lazySizesConfig = window.lazySizesConfig || {}
    window.lazySizesConfig.init = false
    new window.CIResponsive({
      token: 'akngmxfhco',
      baseURL: 'https://running-pace.org/img/',
      lazyLoading: true
    })
    window.lazySizes.init()
  }
}
</script>
