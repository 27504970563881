const checkMethods = {
  methods: {
    isNumber: function(event) {
      event = (event) ? event : window.event;
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault()
      } else {
        return true;
      }
    },

    isDigit: function(event, length) {
      event = (event) ? event : window.event;
      if (event.target.value.length === length) {
        event.preventDefault()
      }
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault()
      } else {
        return true;
      }
    }
  }
};
export default checkMethods
