const defaultValues = {
  data: function() {
    return {
      defaultValues: {
        // km
        distance: 10.000,
        // s
        time: 3000,
        // km/h
        speed: 12.000,
        // s/min
        pace: 300,

        // options
        unit: 'km',
        target: 'distance',
        increment: 1,

        // advancedOptions
        // km
        minDistance: 1,
        maxDistance: 161,
        // min
        minTime: 1,
        // 1440 min = 24 * 60 = 1 day
        maxTime: 1440,
        minSpeed: 5,
        maxSpeed: 24,
        displayClassicDistances: 1,
        numberOfLines: 5,
        numberOfSeconds: 1,
      }
    }
  }
}
export default defaultValues
