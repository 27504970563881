<template>
  <div>
    <div class="shadowed-content margin-form">
      <p v-html="$t('home.help')"></p>

      <selection-form
        :targets="targets"
        :options="options"
        :advanced-options="advancedOptions">
      </selection-form>

      <div class="btn-group margin-buttons">
        <button class="btn" @click.prevent="$refs.classicDistancesModal.show()">
          <font-awesome-icon :icon="['fal', 'hand-pointer']" size="2x"></font-awesome-icon> {{ $t('Classic Distances') }}
        </button>
        <button class="btn" @click.prevent="$refs.worldRecordsModal.show()">
          <font-awesome-icon :icon="['fal', 'stopwatch']" size="2x"></font-awesome-icon> {{ $t('World Records') }}
        </button>
        <button class="btn" @click.prevent="$refs.advancedOptionsModal.show()">
          <font-awesome-icon :icon="['fal', 'cog']" size="2x"></font-awesome-icon> {{ $t('Advanced Options') }}
        </button>
      </div>
    </div>

    <classic-distances-modal
      ref="classicDistancesModal"
      :selected-unit="options.unit">
    </classic-distances-modal>

    <world-records-modal
      ref="worldRecordsModal"
      :selected-unit="options.unit">
    </world-records-modal>

    <advanced-options-modal
      ref="advancedOptionsModal"
      :unit="options.unit"
      :advanced-options="advancedOptions">
    </advanced-options-modal>

    <!-- Main content -->
    <div id="main-content" class="shadowed-content">
      <h2>{{ title }}</h2>

      <table id="times" class="table table-scroll table-striped table-hover">
        <template v-for="(row, i) in tableData">
          <thead v-if="i == 0" v-bind:key="i">
            <tr>
              <th v-for="(time, j) in row" v-bind:key="j">{{ time }}</th>
            </tr>
          </thead>
          <tbody v-else v-bind:key="i">
            <tr>
              <td v-for="(time, j) in row" v-bind:key="j" :class="i == advancedOptions.numberOfLines + 1 ? 'targeted-pace': ''">{{ time }}</td>
            </tr>
          </tbody>
        </template>
      </table>

      <div class="btn-group">
        <button class="btn" @click="goFullscreen" v-show="!fullscreen">
          <font-awesome-icon :icon="['fal', 'arrows-alt']" size="2x"></font-awesome-icon> {{ $t('Toogle Fullscreen') }}
        </button>
        <button class="btn" @click="exitFullscreen" v-show="fullscreen">
          <font-awesome-icon :icon="['fal', 'compress-arrows-alt']" size="2x"></font-awesome-icon> {{ $t('Exit Fullscreen Mode') }}
        </button>
        <button class="btn tooltip" :class="tooltipClass" :data-tooltip="tooltipText" @click="copyUrlTo">
          <font-awesome-icon :icon="['fal', 'bookmark']" size="2x"></font-awesome-icon> {{ $t('Copy URL to Clipboard') }}
        </button>
        <download-csv :data="tableData" class="btn" name="running-pace.csv">
          <button class="btn">
            <font-awesome-icon :icon="['fal', 'file-csv']" size="2x"></font-awesome-icon> {{ $t('Export as CSV') }}
          </button>
        </download-csv>
        <button class="btn" @click="downloadPdf">
          <font-awesome-icon :icon="['fal', 'file-pdf']" size="2x"></font-awesome-icon> {{ $t('Export as PDF') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment-duration-format'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import 'spectre.css/dist/spectre.css'
import JsonCSV from 'vue-json-csv'
import * as clipboard from 'clipboard-polyfill'
import EventBus from '@/eventBus'
import { km2mi } from '@/constants'
import defaultValues from '@/defaultValues'
import formatterMethods from '@/formatterMethods'
import classicDistances from '@/classicDistances'
import worldRecords from '@/worldRecords'
import ClassicDistancesModal from '@/components/ClassicDistancesModal.vue'
import WorldRecordsModal from '@/components/WorldRecordsModal.vue'
import AdvancedOptionsModal from '@/components/AdvancedOptionsModal.vue'
import SelectionForm from '@/components/SelectionForm.vue'

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: this.$t('home.meta.title'),
      meta: [
        { name: 'description', content: this.$t('home.meta.description') },

        // Open Grath tags
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://running-pace.org/img/iStock-subman-og.jpg' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '627' },
        { property: 'og:url', content: 'https://running-pace.org' + this.$t('/en/index.html') },
        { property: 'og:title', content: this.$t('home.meta.title') },
        { property: 'og:description', content: this.$t('home.meta.description') },

        // Twitter Card tags
        { name: 'twitter:card', content: 'summary' },
        // Fallback to open graph properties
        //{ name: 'twitter:url', content: 'https://running-pace.org' + this.$t('/en/index.html') },
        //{ name: 'twitter:title', content: this.$t('home.meta.title') },
        //{ name: 'twitter:description', content: this.$t('home.meta.description') },
        { name: 'twitter:image', content: 'https://running-pace.org/img/iStock-subman-tc.jpg' }
      ]
    }
  },
  mixins: [defaultValues, formatterMethods, classicDistances, worldRecords],
  components: {
    SelectionForm,
    AdvancedOptionsModal,
    ClassicDistancesModal,
    WorldRecordsModal,
    DownloadCsv: JsonCSV
  },

  /* ----- Data variables ----- */

  data: function () {
    return {
      targets: {
        // Distance in km or mi (float with 3 decimal places)
        distance: null,
        // Distance label (null if the distance is not classic)
        classicDistanceLabel: null,
        // Targeted time (s)
        time: null,
        // Speed in km/h or mi/h
        speed: null,
        // Pace in s/km or s/mi
        pace: null
      },

      options: {
        // km or mi
        unit: null,
        target: null,
        // Increment in km or mi
        increment: null
      },

      advancedOptions: {
        // Min and max time when target is the distance
        minDistance: null,
        maxDistance: null,
        // Min and max time when target is the time
        minTime: null,
        maxTime: null,
        minSpeed: null,
        maxSpeed: null,
        displayClassicDistances: 1,
        // Number of lines before and after pace target in the table
        numberOfLines: null,
        // Number of seconds offset for each line in the table
        numberOfSeconds: null
      },

      // Other properties
      contentClass: 'grid-xl',
      fullscreen: false,
      tooltipText: '',
      tooltipClass: 'tooltip-success'
    }
  },

  /* ----- Computed variables ----- */

  computed: {
    /* Content title */
    title: function() {

      // Distance
      let title;
      const formattedDistance = this.round(this.targets.distance)
      switch (this.targets.classicDistanceLabel) {
        case '5K':
        case '10K':
          title = this.$t(this.targets.classicDistanceLabel)
          if (this.options.unit === 'mi') {
            title += ' (' + formattedDistance + ' mi)';
          }
          break;
        case 'Half Marathon':
        case 'Marathon':
          title = this.$t(this.targets.classicDistanceLabel)
            + ' ('
            + this.round(this.targets.distance)
            + ' '
            + this.options.unit
            + ')';
          break;
        case '100K':
          title = this.$t('100K')
          if (this.options.unit === 'mi') {
            title += ' (' + formattedDistance + ' mi)';
          }
          break;
        case '100-Mile':
          title = this.$t('100-Mile')
          if (this.options.unit === 'km') {
            title += ' (' + formattedDistance + ' km)';
          }
          break;
        default:
          title = formattedDistance + ' ' + this.options.unit;
          break;
      }

      // Time
      let printCentiseconds = false;
      if (this.options.unit == 'km' && this.targets.distance <= 10) {
        printCentiseconds = true;
      }
      if (this.options.unit == 'mi' && this.targets.distance <= 10 * km2mi) {
        printCentiseconds = true;
      }
      title += ' - ' + ' ' + this.formatTime(this.targets.time, printCentiseconds);

      // Speed
      title += ' - ' + this.formatSpeed(this.targets.speed, this.options.unit);

      return title;
    },

    /* Labels */
    speedLabel: function () {
      return (this.options.unit === 'km') ? this.$t('Speed (km/h)') : this.$t('Speed (mi/h)');
    },
    paceLabel: function () {
      return (this.options.unit === 'km') ? this.$t('Pace per km (min)') : this.$t('Pace per mi (min)');
    },
    distanceLabel: function () {
      return (this.options.unit === 'km') ? this.$t('Distance (km)') : this.$t('Distance (mi)');
    },
    incrementLabel: function () {
      return this.$t('Increment') + ' (' + this.options.unit + ')';
    },
    speedAbbreviation: function () {
      return this.options.unit + '/h';
    },

    /*
     * Return the distances to display according to the targeted distance, the
     * increment and the fact that classic distances must be displayed or not.
     */
    distances: function () {
      const distances = []

      // Add target distance
      distances.push(this.targets.distance)

      // Add distances defined by the increments
      let distance = this.options.increment
      while (distance <= this.targets.distance) {
        distances.push(this.round(distance))
        distance = parseFloat(distance) + parseFloat(this.options.increment)
      }

      // Add classic distances
      if (this.advancedOptions.displayClassicDistances === 1) {
        for (const distanceLabel in classicDistances) {
          if (classicDistances[distanceLabel] <= this.targets.distance) {
            distances.push(this.round(classicDistances[distanceLabel]))
          } else {
            break
          }
        }
      }

      distances.sort(function (a, b) { return a - b })
      return Array.from(new Set(distances))
    },

    tableData: function() {
      const data = [];
      let i, row, pace, time, duration, format;

      // Add headers
      const headers = [];
      headers.push(this.$t('Pace') + ' (min/' + this.options.unit + ')');
      for (i = 0; i < this.distances.length; i++) {
        headers.push(this.formatDistance(this.distances[i], this.options.unit));
      }
      data.push(headers);

      // Add content
      for (i = -this.advancedOptions.numberOfLines; i <= this.advancedOptions.numberOfLines; i++) {
        row = []

        pace = this.targets.pace + this.advancedOptions.numberOfSeconds * i
        row.push(this.formatPace(pace, null, false))

        for (let j = 0; j < this.distances.length; j++) {
          time = this.distances[j] * pace
          duration = moment.duration(time, 'seconds')
          format = (duration.hours() > 0) ? 'hh:mm:ss' : 'mm:ss'
          row.push(duration.format(format))
        }

        data.push(row);
      }
      return data;
    }
  },

  watch: {
    targets: function() {
      const selectedDistance = this.round(this.targets.distance)
      for (let classicDistance in classicDistances) {
        classicDistance = classicDistances[classicDistance]
        if (this.options.unit === 'mi') {
          classicDistance *= km2mi
        }
        classicDistance = this.round(classicDistance)

        if (selectedDistance == classicDistance) {
          this.targets.classicDistanceLabel = classicDistance
        } else {
          this.targets.classicDistanceLabel = null
        }
      }
    }
  },

  /* ----- Created method ----- */

  created: function() {
    this.targets.distance = this.defaultValues.distance
    this.targets.time = this.defaultValues.time
    this.targets.speed = this.defaultValues.speed
    this.targets.pace = this.defaultValues.pace

    this.options.unit = this.defaultValues.unit
    this.options.target = this.defaultValues.target
    this.options.increment = this.defaultValues.increment

    this.advancedOptions.minDistance = this.defaultValues.minDistance
    this.advancedOptions.maxDistance = this.defaultValues.maxDistance
    this.advancedOptions.minTime = this.defaultValues.minTime * 60
    this.advancedOptions.maxTime = this.defaultValues.maxTime * 60
    this.advancedOptions.minSpeed = this.defaultValues.minSpeed
    this.advancedOptions.maxSpeed = this.defaultValues.maxSpeed
    this.advancedOptions.displayClassicDistances = this.defaultValues.displayClassicDistances
    this.advancedOptions.numberOfLines = this.defaultValues.numberOfLines
    this.advancedOptions.numberOfSeconds = this.defaultValues.numberOfSeconds

    // https://stackoverflow.com/questions/16605769/simple-javascript-encryption-decryption-without-using-key/16605836
    if (window.location.hash) {
      this.parseUrlArguments(window.location.hash)
    }
  },

  mounted: function() {
    let self = this
    EventBus.$on('classic-distance-selected', function (payload) {
      let distance = classicDistances[payload.distanceLabel]
      if (self.options.unit === 'mi') {
        distance *= km2mi
      }
      self.targets.distance = distance
      self.targets.classicDistanceLabel = payload.distanceLabel
    });

    EventBus.$on('world-record-selected', function (payload) {
      self.targets.classicDistanceLabel = payload.distanceLabel
      self.targets.distance = classicDistances[payload.distanceLabel]
      self.targets.pace = payload.worldRecord.pace
      self.targets.speed = payload.worldRecord.speed
      self.targets.time = payload.worldRecord.time

      if (self.unit === 'mi') {
        self.targets.distance *= km2mi
        self.targets.pace /= km2mi
        self.targets.speed *= km2mi
      }
    });

    EventBus.$on('selection-form-modified', function (payload) {
      // Update targets
      self.targets.distance = payload.targets.distance
      self.targets.time = payload.targets.time
      self.targets.speed = payload.targets.speed
      self.targets.pace = payload.targets.pace

      // Update options
      self.options.unit = payload.options.unit
      self.options.target = payload.options.target
      self.options.increment = payload.options.increment

      // Check if the selected distance is classic
      for (let classicDistanceLabel in classicDistances) {
        let classicDistance = classicDistances[classicDistanceLabel]
        if (self.options.unit === 'mi') {
          classicDistance *= km2mi
        }
        if (self.round(self.targets.distance) == self.round(classicDistance)) {
          self.targets.classicDistanceLabel = classicDistanceLabel
          break
        } else {
          self.targets.classicDistanceLabel = null
        }
      }

      self.updateHash()
    })

    EventBus.$on('advanced-options-modified', function (payload) {

      // No sanity check (eg check if the distance is lower than minDistance when target is distance)
      // because this kind of tests are performed in event handlar of advanced-options-modified in
      // SelectionForm and then a new selection-form-modified event will be sent if needed

      self.advancedOptions.minDistance = payload.minDistance
      self.advancedOptions.maxDistance = payload.maxDistance
      self.advancedOptions.minTime = payload.minTime
      self.advancedOptions.maxTime = payload.maxTime
      self.advancedOptions.minSpeed = payload.minSpeed
      self.advancedOptions.maxSpeed = payload.maxSpeed
      self.advancedOptions.displayClassicDistances = payload.displayClassicDistances
      self.advancedOptions.numberOfLines = payload.numberOfLines
      self.advancedOptions.numberOfSeconds = payload.numberOfSeconds

      self.updateHash()
    })

    window.addEventListener('fullscreenchange', function() {
      self.fullscreen = (document.fullscreenElement !== null)
    })
  },

  /* ----- Methods ----- */

  methods: {

    updateHash() {
      // Update hash
      const hash =
              '#' + this.options.unit +
              '/' + this.options.target +
              '/' + this.options.increment +
              '/' + this.advancedOptions.minDistance +
              '/' + this.advancedOptions.maxDistance +
              '/' + this.advancedOptions.minTime +
              '/' + this.advancedOptions.maxTime +
              '/' + this.advancedOptions.minSpeed +
              '/' + this.advancedOptions.maxSpeed +
              '/' + this.advancedOptions.numberOfLines +
              '/' + this.advancedOptions.numberOfSeconds +
              '/' + this.advancedOptions.displayClassicDistances +
              '/' + this.targets.distance +
              '/' + this.targets.time +
              '/' + this.targets.speed +
              '/' + this.targets.pace
      // https://stackoverflow.com/questions/16605769/simple-javascript-encryption-decryption-without-using-key/16605836
      window.location.hash = window.btoa(hash)
    },

    // Parse the given url arguments
    // If any error is found, no param is set
    parseUrlArguments(hash) {
      const params = (window.atob(hash.substr(1))).split('/')

      if (params.length === 16) {

        // Options

        // Parse unit
        let unit = params[0]
        if (unit === '#km') {
          unit = 'km'
        } else if (unit === '#mi') {
          unit = 'mi'
        } else {
          return
        }

        // Parse target
        const target = params[1]
        if (target !== 'distance' && target !== 'time') {
          return
        }

        // Parse increment
        const increment = parseFloat(params[2])
        if (isNaN(increment) || increment <= 0) {
          return
        }

        // Advanced options

        // Parse minDistance
        const minDistance = parseFloat(params[3]);
        if (isNaN(minDistance)) {
          return
        }

        // Parse maxDistance
        const maxDistance = parseFloat(params[4]);
        if (isNaN(maxDistance)) {
          return
        }

        // Parse minTime
        const minTime = parseFloat(params[5]);
        if (isNaN(minTime)) {
          return
        }

        // Parse maxTime
        const maxTime = parseFloat(params[6]);
        if (isNaN(maxTime)) {
          return
        }

        // Parse minSpeed
        const minSpeed = parseFloat(params[7]);
        if (isNaN(minSpeed)) {
          return
        }

        // Parse maxSpeed
        const maxSpeed = parseFloat(params[8]);
        if (isNaN(maxSpeed)) {
          return
        }

        // Parse numberOfLines
        const numberOfLines = parseInt(params[9]);
        if (isNaN(numberOfLines)) {
          return
        }

        // Parse numberOfSeconds
        const numberOfSeconds = parseInt(params[10]);
        if (isNaN(numberOfSeconds)) {
          return
        }

        // Parse displayClassicDistances
        const displayClassicDistances = parseInt(params[11])
        if (displayClassicDistances !== 0 && displayClassicDistances !== 1) {
          return
        }

        // Targets

        // Parse distance
        const distance = parseFloat(params[12])
        if (isNaN(distance)) {
          return
        }
        // Parse time
        const time = parseFloat(params[13])
        if (isNaN(time)) {
          return
        }

        // Parse speed
        const speed = parseFloat(params[14]);
        if (isNaN(speed) || speed < minSpeed || speed > maxSpeed) {
          return
        }

        // Parse pace
        const pace = parseFloat(params[15]);
        if (isNaN(pace)) {
          return
        }

        // Sanity checks
        if (increment > distance) {
          return
        }
        if (target === 'distance' && (distance < minDistance || distance > maxDistance)) {
          return
        }
        // TODO
        //if (target === 'time' && (time < minTime || time > maxTime)) {
        //  return
        //}
        if (this.round(time, 2) !== this.round((3600 * distance / speed), 2)) {
          return
        }

        this.options.unit = unit
        this.options.target = target
        this.options.increment = increment
        this.advancedOptions.minDistance = minDistance
        this.advancedOptions.maxDistance = maxDistance
        this.advancedOptions.minTime = minTime
        this.advancedOptions.maxTime = maxTime
        this.advancedOptions.minSpeed = minSpeed
        this.advancedOptions.maxSpeed = maxSpeed
        this.advancedOptions.displayClassicDistances = displayClassicDistances
        this.advancedOptions.numberOfLines = numberOfLines
        this.advancedOptions.numberOfSeconds = numberOfSeconds
        this.targets.distance = distance
        this.targets.time = time
        this.targets.speed = speed
        this.targets.pace = pace
      }
    },

    copyUrlTo() {
      const url = window.location.toString();
      const self = this;
      clipboard.writeText(url)
        .then(function() {
          self.tooltipText = self.$t('The URL has been succesfully copied.');
          self.tooltipClass = "tooltip-success";
        })
        .catch(function() {
          self.tooltipText = self.$t('Sorry, cannot copy the URL.');
          self.tooltipClass = "tooltip-error";
        });
      setTimeout(function() {
        self.tooltipText = null
        self.tooltipClass = null
      }, 4000);
    },

    downloadPdf() {
      // Try to set pdf optimal size according to the number of colums of the table
      let size = 'a4';
      if (this.distances.length >= 40) {
        size = 'a1';
      } else if (this.distances.length >= 30) {
        size = 'a2';
      } else if (this.distances.length >= 20) {
        size = 'a3';
      }

      const doc = new jsPDF('landscape', 'mm', size)
      const totalPagesExp = '{total_pages_count_string}'
      const self = this
      doc.autoTable({
        html: '#times',
        didDrawPage: function(data) {
          // Header
          doc.setFontSize(20)
          doc.setTextColor(40)
          doc.setFont(undefined, 'normal')
          doc.text('Running Pace', data.settings.margin.left, 10)
          doc.setFontSize(16)
          doc.text(self.title, data.settings.margin.left, 20)

          // Footer
          let str = 'Page ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
          }
          str += ' - Generated by running-pace.org.'
          doc.setFontSize(10)
          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          const pageSize = doc.internal.pageSize
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
        margin: { top: 30 },
      });
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      doc.save('running-pace.pdf')
    },

    // Whack fullscreen
    exitFullscreen: function () {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      this.fullscreen = false;      
    },

    goFullscreen: function () {
      const div = document.getElementById('main-content');
      // Find the right method, call on correct element
      if (div.requestFullscreen) {
        div.requestFullscreen();
      } else if (div.mozRequestFullScreen) {
        div.mozRequestFullScreen();
      } else if (div.webkitRequestFullscreen) {
        div.webkitRequestFullscreen();
      } else if (div.msRequestFullscreen) {
        div.msRequestFullscreen();
      }
      this.fullscreen = true;
    },

    changeLocale(locale) {
      this.$root.$i18n.locale = locale;
    }
  }
}
</script>

<style scoped>
.margin-form {
  margin-bottom: 25px;
}

.margin-buttons {
  margin-top: 25px;
}

.table {
  font-size: 0.7rem;
  margin-bottom: 20px;
}

.table.table-hover tbody tr:hover {
  background: #f8dbda;
}

.targeted-pace {
  font-weight: bold;
  font-size: 0.75rem
}

/* Fullscreen */
#main-content:fullscreen {
  background: rgb(252,252,252);
  background: radial-gradient(circle, rgba(252,252,252,1) 0%, rgba(154,204,255,1) 100%);
  text-align: center;
  overflow-y: scroll;
}

#main-content:fullscreen table {
  background-color: #FFF;
  box-shadow: 5px 5px 15px 1px #000;
  padding: 10px 0 20px 0;
}

#main-content:fullscreen .btn-group {
  margin-top: 10px;
}

@media (max-width: 769px) {
  .shadowed-content:fullscreen {
    padding: 10px 0;
  }
}

/* Tooltip */
.tooltip::after {
  background: #FFF;
  transition: opacity 2s, transform 2s;
  font-size: 0.8rem;
  max-width: 350px;
}

.tooltip-success::after {
  color: #32b643;
}

.tooltip-error::after {
  color: #ee2d4d;
}
</style>
