<template>
  <div id="world-records-modal" class="modal modal-lg" :class="{ active: showModal }">
    <a href="#close" class="modal-overlay" :aria-label="$t('Close')" @click.prevent="close"></a>
    <div class="modal-container shadowed-content">
      <div class="modal-header">
        <a href="#close" class="btn btn-clear float-right" :aria-label="$t('Close')" @click.prevent="close"></a>
        <div class="modal-title h5">{{ $t('World Records') }}</div>
      </div>
      <div class="modal-body">
        <div class="content">
          <div class="container">

            <div v-for="distanceLabel in classicDistanceLabels" :key="distanceLabel" class="columns">
              <div class="column col-2 col-lg-12">
                <classic-distance-milestone :label="distanceLabel" :unit="selectedUnit" />
              </div>
              <div class="column col-1 col-lg-12"></div>
              <div class="column col-4 col-lg-12">
                <world-record-card 
                  :distanceLabel="distanceLabel"
                  :sex="'m'"
                  :unit="selectedUnit" 
                />
              </div>
              <div class="column col-1 col-lg-12"></div>
              <div class="column col-4 col-lg-12">
                <world-record-card 
                  :distanceLabel="distanceLabel"
                  :sex="'w'"
                  :unit="selectedUnit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <p v-html="$t('worldRecordsModal.footer')"></p>

        <div class="text-center">
          <div class="btn-group">
            <button class="btn btn-link" @click.prevent="close">
              <font-awesome-icon :icon="['fal', 'times-circle']" size="2x"></font-awesome-icon> {{ $t('Cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../eventBus'
import classicDistances from '../classicDistances.js'
import WorldRecordCard from './WorldRecordCard.vue'
import ClassicDistanceMilestone from './ClassicDistanceMilestone.vue'

export default {
  name: 'WorldRecordsModal',
  props: {
    'selectedUnit': String
  },
  components: {
    WorldRecordCard,
    ClassicDistanceMilestone
  },
  data: function() {
    return {
      showModal: false
    }
  },
  computed: {
    classicDistanceLabels: function() {
      return Object.keys(classicDistances)
    }
  },
  mounted: function() {
    const self = this
    EventBus.$on('classic-distance-selected', function () {
      self.close()
    });

    EventBus.$on('world-record-selected', function () {
      self.close()
    });    
  },
  methods: {
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    }
  }
}
</script>

<style scoped>
#world-records-modal .modal-container {
  max-width: 1100px;
}

#world-records-modal .column {
  padding: 0.4rem;
}

#world-records-modal .shadowed-content {
  padding: 30px 30px;
}

.modal-footer p {
  text-align: right;
  margin-top: 10px;
}
</style>
