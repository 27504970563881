<template>
  <div>
    <div class="shadowed-content">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: $t('/en/index.html') }">{{ $t('Home') }}</router-link>
        </li>
        <li class="breadcrumb-item">{{ $t('about.title') }}</li>
      </ul>

      <h2>{{ $t('about.title') }}</h2>
      <p v-html="$t('about.this.website')"></p>
      <p v-html="$t('about.image.credit')"></p>
      <p v-html="$t('about.plausible')"></p>

      <h3>{{ $t('Legal Notice') }}</h3>

      <h4>{{ $t('Hosting') }}</h4>
      <p>
        <a href="https://www.infomaniak.com/">Infomaniak Network SA</a><br>
        Rue Eugène Marziano 25<br>
        1227 Les Acacias (GE)<br>
        {{ $t('Switzerland') }}<br>
      </p>

      <h4>{{ $t('Cookies') }}</h4>
      <p v-html="$t('about.cookies')"></p>

      <h3>{{ $t('Related websites') }}</h3>
      <ul>
        <li><a href="https://www.running-addict.fr/calculateurs/allures-entrainement/calculateur-allure-entrainement-course-a-pied.html">Calculateur d'allures d'entrainement</a> (<a href="https://www.running-addict.fr/">Running Addict</a>)</li>
        <li><a href="https://marathonpacechart.com/">Marathon Pace Chart</a></li>
        <li><a href="https://www.strava.com/running-pace-calculator">Running Pace Calculator</a> (<a href="https://www.strava.com/">Strava</a>)</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo() {
    return {
      title: this.$t('about.meta.title'),
      meta: [
        { name: 'description', content: this.$t('about.meta.description') },

        // Open Graph tags
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://running-pace.org/img/iStock-subman-og.jpg' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '627' },
        { property: 'og:url', content: 'https://running-pace.org' + this.$t('/en/about-this-website.html') },
        { property: 'og:title', content: this.$t('about.meta.title') },
        { property: 'og:description', content: this.$t('about.meta.description') },

        // Twitter Card tags
        { name: 'twitter:card', content: 'summary' },
        // Fallback to open graph properties
        //{ name: 'twitter:url', content: 'https://running-pace.org' + this.$t('/en/about-this-website.html') },
        //{ name: 'twitter:title', content: this.$t('about.meta.title') },
        //{ name: 'twitter:description', content: this.$t('about.meta.description') },
        { name: 'twitter:image', content: 'https://running-pace.org/img/iStock-subman-tc.jpg' }
      ]
    }
  }
}
</script>

<style scoped>
  .columns + h3 {
    margin-top: 24px;
  }
</style>
